.contact-info-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--primary-font-family);
    gap: 10px;
}

.contact-info-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    padding: 5px 10px;
    background-color: #fafafa;
    box-shadow: 0 2px 5px 0 rgb(207, 207, 207);
}

.contact-info-row span {
    font-weight: bold;
    color: #a80000;
}

.contact-info-row p {
    color: #555;
}

@media (max-width: 600px) {
    .contact-info-box {
        width: 100%; /* Full width on smaller screens */
        padding: 10px;
    }

    .contact-info-row {
        flex-direction: column; /* Stack span and p vertically */
        align-items: flex-start;
    }

    .contact-info-row span {
        margin-bottom: 5px;
    }

    .contact-info-row p {
        margin-left: 0;
        text-align: left;
    }
}

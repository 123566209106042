
.login-page {
    display: flex;
    flex-direction: column;
}

.login-page-title {
    font-size: 48px;
    font-style: bold;
    font-family: var(--secondary-font-family);
    margin: auto;
    margin-bottom: 50px;
    margin-top: 50px;
    text-align: center;
}

.login-page-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    height: 200px;
    padding: 20px;
    margin: auto;
    box-shadow: 0 2px 5px 2px rgb(207, 207, 207);
}

.login-page-inner input, .error-container {
    width: 30vw;
    margin: auto;
}

.login-submit-button { 
    padding: 5px;
    width: 75px;
    border-radius: 0.7em;
    margin: auto;
    background-color: white;
    border: 1px solid black;
    color: black;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
}

.login-submit-button:hover { 
    transform: scale(1.05);
    background-color: #a80000;
    color: white;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
}

.error-container {
    display: flex;
    height: 18px;
    font-size: 14px;
    color: red;
}

.spinner-wrapper {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hidden { 
    display: none;
}

.confirmation-page-inner {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    margin-top: 10vh;
}

.thank-you-msg { 
    font-size: 16px;
    font-weight: 600;
    color: green;
    margin-top: 5vh;
    margin-bottom: 5vh;
}
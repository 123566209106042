

/* ---- General classes ---- */
.form-page form { 
    width: 80%;
    margin: auto;
    margin-top: 10vh;
}


.input-section { 
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
}

.section-header { 
    font-family: 'Sacramento', serif;
    font-size: 48px;
    font-weight: 100;
    width: 100%;
    margin-bottom: 5vh;
    border-bottom: 1px solid #a80000;
    color: black;
}

.input-row { 
    display: flex;
    flex-direction: row;
    margin-bottom: 3vh;
}

.form-page label { 
    font-size: 20px;
    color: #a80000;
    font-weight: bold;
    font-family: var(--primary-font-family);
    margin-bottom: 1vh;
}

/* ---- Order info section ---- */
#order-info-section { 
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
}

#order-info-section .input-row { 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 80%;
}

#order-date, .custom-time-picker { 
    margin: auto;
    width: 200px;
    height: 30px;
}

.input-row label { 
    width: 200px;
    margin: auto;
    text-align: center;
}

.customer-info-input-row input, .custom-date-input, .custom-time-picker {
    font-size: 16px;
}

.customer-info-input-row { 
    width: 70% !important;
}

.date-time-input-row { 
    width: 30vw !important;
}

/* ---- Menu items sections ---- */
.menu-item-row { 
    display: flex;
    margin-top: 1.5vh;
    flex-direction: row;
    align-items: center;
}

.menu-item-input { 
    width: 4vw;
    height: 2vh;
    margin-right: 2vw;
    font-size: 14px;
}

.menu-item-label { 
    font-weight: 300;
}

.menu-item-label .item-label { 
    font-size: 16px;
}

.menu-item-label .item-price-label {
    font-size: 12px;
    margin-left: 10px;
}


/* ---- Confirmation section ---- */
.confirmation-table { 
    display: table;
    border-collapse: collapse;
    margin-top: 1vh;
    font-family: var(--primary-font-family);
    font-size: 16px;
}

.confirmation-table thead { 
    background-color: rgb(204, 204, 204);
}

.confirmation-table td, .confirmation-table th { 
    padding: 10px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-left: 1px solid rgb(200, 200, 200);
    border-right: 1px solid rgb(200, 200, 200);
}

.payment-info-row { 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3vh;
    margin-bottom: 3vh;
}

.payment-info-row input {
    margin-top: 1vh;
    font-size: 14px;
    padding: 5px;
}

.policy-box { 
    border: 1px solid grey;
    padding: 10px;
}

.policy-content { 
    font-size: 14px;
    color: black;
}

.policy-agreement-row { 
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 1.5vh;
}

#policy-agreement-checkbox { 
    width: 15px;
    height: 15px;
}

.policy-agreement-text { 
    font-size: 14px;
    color: black;
    margin: 0;
    font-family: var(--primary-font);
}

.submit-button { 
    width: 10vw;
    padding: 10px;
    font-family: var(--primary-font);
    font-size: 14px;
    margin-top: 4vh;
    border-radius: 1rem;
    border: 1px solid #a80000;
    background-color: white;
    cursor: pointer;
    transition: 0.3s ease;
}

.submit-button:hover { 
    transition: 0.3s ease;
    transform: scale(1.05);
    background-color: #a80000;
    color: white;
    border-color: black;
}

.form-page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-shadow: 0 2px 5px 0 rgb(207, 207, 207);
}

.header-image { 
    position: absolute;
    width: 100px;
}

.header-link { 
    font-family: var(--primary-font-family);
    font-size: 18px;
    text-align: center;
    color: #a80000;
    text-decoration: none;
    margin: auto;
    width: 10vw;
    min-height: 22px;
    padding: 10px;
    border-left: 1px solid rgb(207, 207, 207);
    border-right: 1px solid rgb(207, 207, 207);
    transition: 0.2s ease;
}

.header-link:visited {
    text-decoration: none;
    transition: 0.2s ease;
}

.header-link:hover {
    cursor: pointer;
    background-color: #a80000;
    color: white;
    transition: 0.2s ease;
}
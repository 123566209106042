
.portal-menu-page {

}

.menu-items-table { 
    display: table;
    table-layout: auto;
    width: 90vw;
    margin: auto;
    margin-top: 5vh;
    box-shadow: 2px 2px 2px 2px grey;
    border-collapse: collapse;
}

.menu-items-table thead { 
    background-color: lightgrey;
}

.menu-items-table thead tr th {
    padding: 5px 0;
}

.menu-items-table tbody tr td { 
    white-space: nowrap;
    padding: 5px 10px;
}

.menu-items-table tbody tr td, .menu-items-table thead tr th {
    border-left: 1px solid lightgrey;
}

.menu-items-table tbody tr {
    border-bottom: 1px solid grey;
}

.pencil-icon { 
    float: right;
}
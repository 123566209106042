
.portal-header {
    display: flex;
    flex-direction: row;
    position: sticky;
    top: 0;
    padding: 0 10px 0 10px;
    background-color: white;
    box-shadow: 0 2px 5px 2px grey;
    z-index: 5;
}

.portal-header-item {
    padding: 10px;
    font-size: 18px;
    font-family: monospace;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: 75px;
    text-align: center;
    transition: 0.2s ease;
}

.portal-header-item {
    text-decoration: none;
    color: black;
}

.portal-header-item.selected { 
    background-color: #a80000;
    color: white;
    transition: 0.2s ease;
}

.portal-header-item:hover { 
    background-color: lightblue;
    cursor: pointer;
    transition: 0.2s ease;
}
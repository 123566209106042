
.orders-search-container {
    width: 85%;
    margin: auto;
    margin-top: 3vh;
    padding: 20px 0 20px 0;
    box-shadow: 2px 2px 2px 2px rgb(207, 207, 207);
}

.portal-home-subheader {
    font-size: 20px;
    text-align: center;
    color: #a80000;
    width: 300px;
    margin: auto;
    margin-bottom: 3vh;
    padding-bottom: 10px;
}

.orders-search-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80vw;
    margin: auto;
}

.orders-search-bar input {
    width: 150px;
    height: 20px;
}

.orders-search-bar button { 
    width: 100px;
    background-color: white;
    border: 1px solid black;
    border-radius: 0.5em;
    padding: 5px;
    transition: 0.2s ease;
}

.orders-search-bar button:hover { 
    background-color: #a80000;
    color: white;
    transform: scale(1.05);
    transition: 0.2s ease;
    cursor: pointer;
}

.orders-list { 
    height: fit-content;
    margin: auto;
    margin-bottom: 10vh;
    margin-top: 5vh;
}


/* .order-div
 *      h1 is the header for each of the individual orders in the list w/ the customer name 
 *      h2 is the subheader with the order ID 
 */
.order-div {     
    width: 70vw;
    margin: auto;
    margin-top: 2vh;
    padding: 10px;
    box-shadow: 2px 2px 2px 2px rgb(207, 207, 207);
}

.order-div h1, .order-div h2 {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
}

.order-div h1 {
    font-size: 18px;
}

/* Subheader with the order ID */
.order-div h2 {
    font-size: 14px;
    font-weight: 400;
    margin-top: 1vh;
}

.order-div h2 span {
    font-weight: bold;
    color: #a80000;
}

.order-div div h3 {
    font-size: 16px;
}

/* Styling for the content for each order div */
.order-details-container { 
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 50px;
    margin-top: 2vh;
}

.order-details-row { 
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0 5px 0;
    font-size: 14px;
    border-bottom: 1px solid grey;
}

/* .order-details-row
 *      label is each of the labels in an order div (e.g. "Customer Phone", "Customer Email", etc)
 *      p is the content of that row of the order div, (e.g. the actual phone number, the actual email etc)
 */
.order-details-row label { 
    font-weight: bold;
    color: #a80000;
    margin: 0;
}

.order-details-row p {
    margin: 0;
}

.order-items-box table {
    display: table;
    width: 100%;
}

.order-items-box table th, .order-items-box table td {
    text-align: left;
    padding: 5px;
}

.order-items-box table th {
    color: #a80000;
}